import './home'
import './login'
import './timetrack'
import './absence'
import './trips'
import './absence_mode'
import './time_entry_profile'
import './time_entry_committed'
import './time_entry_payout'
import './calendar'
import './settings/user'
import './settings/group'
import TeamsTimetrackHeader from "./timetrack/header";



const currentPath = document.body.getAttribute("data-current-path");
if (currentPath && currentPath.indexOf("teams/") !== -1) {
    new TeamsTimetrackHeader()
    const u = new URL(document.location.href)
    document.querySelectorAll("a").forEach((elem) => {
        let url = elem.getAttribute("href")
        if (url) {
            if (url.indexOf("https://") === -1) {
                url = document.location.origin+url
            }
            if (url.indexOf("javascript:") === -1) {
                const uri = new URL(url)
                if(!uri.searchParams.get("ad_token") && u.searchParams.get("ad_token")) {
                    uri.searchParams.set("ad_token", u.searchParams.get("ad_token") || "")
                }
                elem.setAttribute("href", uri.href)
            }
        }
    })
}